import React, { useEffect, useRef, useState } from 'react'
import { BoxCounter } from './BoxCounter'
import styles from './SelectPerson.module.scss'

export const SelectPerson = ({
  search,
  rooms,
  setRooms,
  adults,
  setAdults,
  youngers,
  setYoungers,
  wpInfo
}) => {
  const { adult, younger, room, person, roomMultiple } = search
  const [show, setShow] = useState(false)
  const refSelect = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = (event) => {
    if (refSelect && refSelect.current && event.target) {
      if (!refSelect.current.contains(event.target)) {
        setShow(false)
      }
    }
  }

  return (
    <div ref={refSelect} onMouseDown={handleClickOutside} className={styles.selectContainer}>
      <div
        className={show ? styles.inputContainerSelect : styles.inputContainer}
        onClick={() => setShow((prev) => !prev)}
      >
        <img src={`${wpInfo.assets_url}/icon-room-light.svg`} width="20" height="20" alt="icon-bed" />
        <p>{`${rooms} ${rooms === 1 ? room : roomMultiple} ${adults + youngers} ${adults + youngers === 1 ? person : person + 's'
          }`}</p>
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          fill="rgb(204, 204, 204)"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
        </svg>
      </div>
      <div className={show ? styles.menuDropdownShow : styles.menuDropdown}>
        <BoxCounter name={room} value={rooms} setValue={setRooms} min={1} max={10} />
        <BoxCounter name={adult} value={adults} setValue={setAdults} min={1} max={10} />
        <BoxCounter name={younger} value={youngers} setValue={setYoungers} min={0} max={10} />
      </div>
    </div>
  )
}
