import React, { Component } from 'react';

import Search from './Search'

import { banner } from '../lang/Banner'

const SearchBarContainer = ({wpInfo}) => {

  function getPathUrl() {
    var lang = 'es-ES';
    if (window.location.pathname.includes("en")) {
      lang = 'en-EN';
    } else if ( window.location.pathname.includes("pt") ){
      lang = 'pt-BR';
    }
    return lang
  }

  const locale = getPathUrl();
  
  const { search } = banner[locale];
  
    return (
      <Search search={search} locale={locale} wpInfo={wpInfo} />
    );
  
}


export default SearchBarContainer;