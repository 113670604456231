import React, { useEffect, useRef, useState } from 'react'

import styles from './BoxCounter.module.scss'
export const BoxCounter = ({ name, value, setValue, min, max }) => {
  return (
    <div className={styles.menuItemContainer}>
      <p>{name}</p>
      <div className={styles.menuButtonContainer}>
        <div
          className={styles.menuButtonLeft}
          onClick={() => setValue((prev) => (prev > min ? --prev : prev))}
        >
          -
        </div>
        <p className={styles.menuNumber}>{value}</p>
        <div
          className={styles.menuButtonRight}
          onClick={() => setValue((prev) => (prev < max ? ++prev : prev))}
        >
          +
        </div>
      </div>
    </div>
  )
}
