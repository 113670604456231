import React, { useEffect, useRef, useState } from 'react'

import { Col, Row } from 'react-bootstrap'
import DayPicker, { DateUtils } from 'react-day-picker'
import styles from './SelectDate.module.scss'

export const SelectDate = ({
  setCheckIn,
  setCheckOut,
  monthsShort,
  monthsLong,
  daysLong,
  daysShort,
}) => {
  const [show, setShow] = useState(false)
  const refSelectDay = useRef()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = (event) => {
    if (refSelectDay && refSelectDay.current && event.target) {
      if (!refSelectDay.current.contains(event.target)) {
        setShow(false)
      }
    }
  }
  const modifiers = {
    highlighted: Date.now(),
  }
  const handleDayClick = (day) => {
    const today = new Date()
    const verifyToday =
      today.getDate() === day.getDate() &&
      today.getMonth() === day.getMonth() &&
      today.getFullYear() === day.getFullYear()
    if (day >= today || verifyToday) {
      const range = DateUtils.addDayToRange(day, { from, to })
      setFrom(range.from)
      setCheckIn(range.from)
      setTo(range.to)
      setCheckOut(range.to)
      if (range.to) {
        setShow(false)
      }
    }
  }

  const numberOfMonths = 2

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date)
      const day = daysLong[newDate.getDay()]
      const dateNumber = newDate.getDate()
      const month = monthsShort[newDate.getMonth()]
      const year = newDate.getFullYear()

      return `${day} ${dateNumber}, ${month} ${year}`
    } else return ''
  }

  return (
    <div ref={refSelectDay} onMouseDown={handleClickOutside} className={styles.selectContainer}>
      <Row>
        <Col md={6} xs={6}>
          <div
            onClick={() => setShow((prev) => !prev)}
            className={show ? styles.inputContainerSelect : styles.inputContainer}
          >
            <p className={styles.subtitle}>CHECK-IN</p>
            <p className={styles.date}>{formatDate(from)}</p>
          </div>
        </Col>
        <Col md={6} xs={6}>
          <div
            onClick={() => setShow((prev) => !prev)}
            className={show ? styles.inputContainerSelect : styles.inputContainer}
          >
            <p className={styles.subtitle}>CHECK-OUT</p>
            <p className={styles.date}>{formatDate(to)}</p>
          </div>
        </Col>
      </Row>

      <div className={show ? styles.menuDropdownShow : styles.menuDropdown}>
        <DayPicker
          className="Selectablepicker"
          numberOfMonths={numberOfMonths}
          months={monthsLong}
          disabledDays={{ before: new Date() }}
          weekdaysShort={daysShort}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={handleDayClick}
        />
      </div>
    </div>
  )
}
