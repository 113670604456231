export const banner = {
  'es-ES': {
    search: {
      title: 'Encuentra descuentos increíbles en más de 180 hoteles',
      country: 'Selecciona un país',
      city: 'Selecciona una ciudad',
      hotel: 'Selecciona un hotel',
      btn: 'Buscar',
      adult: 'Adulto',
      younger: 'Menores',
      room: 'Habitación',
      roomMultiple: 'Habitaciones',
      person: 'persona',
      monthsShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      monthsLong: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      daysLong: ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab'],
      daysShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    },
  },
  'pt-BR': {
    search: {
      title: 'Encontre descontos imperdíveis em mais de 180 hotéis',
      country: 'Selecione um pais',
      city: 'Selecione uma cidade',
      hotel: 'Selecione um hotel',
      btn: 'Pesquisar',
      adult: 'Adulto',
      younger: 'Filhos',
      room: 'Quarto',
      roomMultiple: 'Quartos',
      person: 'Adulto',
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      monthsLong: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      daysLong: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    },
  },
  'en-EN' : {
    search: {
      title: 'Find amazing discounts in more than 180 hotels',
      country: 'Select a country',
      city: 'Select a city',
      hotel: 'Select a hotel',
      btn: 'Search',
      adult: 'Adult',
      younger: 'Children',
      room: 'Room',
      roomMultiple: 'Rooms',
      person: 'Person',
      monthsShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      monthsLong: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      daysLong: ['Sun', 'Mond', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      daysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    },
  }
}
