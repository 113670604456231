/* global window, document */
if (! window._babelPolyfill) {
  require('@babel/polyfill');
}

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/globals.scss'

import SearchBarContainer from './containers/SearchBarContainer.jsx';

document.addEventListener('DOMContentLoaded', function() {
  const container = document.getElementById('wyn-searchbar-app');
  const objectId = container.getAttribute('data-object-id');
  ReactDOM.render(<SearchBarContainer wpInfo={window[objectId]} />, container);
});
