/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { searchData } from '../../utils/searchData'
import styles from './Search.module.scss'
import { SelectDate } from './SelectDate'
import { SelectPerson } from './SelectPerson'

function getLang(element) {
  if ( element === 'es-ES') {
    return '/es-xl';
  } else if ( element === 'pt-BR' ) {
    return '/pt-br';
  } else {
    return '';
  }
}

const Search = ({ search, locale, wpInfo }) => {
  const { title, btn, country, city, hotel, monthsShort, monthsLong, daysLong, daysShort } = search
  const { countries, cities, hotels } = searchData
  const [countrySelected, setCountrySelected] = useState()
  const [citySelected, setCitySelected] = useState()
  const [cityData, setCityData] = useState([])
  const [hotelSelected, setHotelSelected] = useState()
  const [hotelData, setHotelData] = useState([])
  const [hrefLink, setHrefLink] = useState('/')
  const [checkIn, setCheckIn] = useState()
  const [checkOut, setCheckOut] = useState()
  const [rooms, setRooms] = useState(1)
  const [adults, setAdults] = useState(1)
  const [youngers, setYoungers] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (countrySelected) {
      setCitySelected(null)
      setHotelSelected(null)
      const resCities = cities.filter((c) => c.countryId === countrySelected.id)
      setCityData(resCities)
    }
  }, [countrySelected])

  useEffect(() => {
    if (citySelected) {
      setHotelSelected(null)
      const resHotels = hotels.filter(
        (c) => c.cityId === citySelected.id && c.countryId === citySelected.countryId,
      )
      setHotelData(resHotels)
    }
  }, [citySelected])

  useEffect(() => {
    const wyndham = 'https://www.wyndhamhotels.com'
    //const lang = locale === 'es-ES' ? 'es-xl' : 'pt-br'
    const lang = getLang(locale)
    let url
    if (hotelSelected) {
      const childrenData = `children=${youngers.toString()}`
      const adultsData = `adults=${adults.toString()}`
      const roomsData = `rooms=${rooms.toString()}`
      url = `${wyndham}${lang}${hotelSelected.url}?${childrenData}&${adultsData}&${roomsData}`
      setHrefLink(url)
      if (checkIn && checkOut) {
        const checkInData = new Date(checkIn)
        const checkOutData = new Date(checkOut)
        const checkInDate = `checkInDate=${
          checkInData.getMonth() + 1
        }/${checkInData.getDate()}/${checkInData.getFullYear()}`
        const checkOutDate = `checkOutDate=${
          checkOutData.getMonth() + 1
        }/${checkOutData.getDate()}/${checkInData.getFullYear()}`
        url = `${wyndham}${lang}${hotelSelected.url}?${childrenData}&${adultsData}&${roomsData}&${checkInDate}&${checkOutDate}`
        setHrefLink(url)
      }
    }
  }, [hotelSelected, youngers, adults, rooms, checkIn, checkOut, locale])

  const iconImage = {
    countries: `url(${wpInfo.assets_url}/icon-gps-light.svg)`,
    city: `url(${wpInfo.assets_url}/icon-gps-light.svg)`,
    hotel: `url(${wpInfo.assets_url}/icon-hotel-light.svg)`,
  }

  const dot = (type) => ({
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Exo 2',
    height: '35px',
    color: '#202020',
    fontSize: '0.85rem',
    ':before': {
      content: '" "',
      display: 'block',
      marginRight: type === 'hotel' ? 20 : 8,
      backgroundImage: iconImage[type],
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: type === 'hotel' ? 22 : 18,
      width: type === 'hotel' ? 16.5 : 18,
    },
  })

  const styleError = (type) => {
    switch (type) {
      case 'hotel':
        if (!hotelSelected && error) {
          return 'red'
        } else {
          return '#ddd'
        }

      case 'countries':
        if (!countrySelected && error) {
          return 'red'
        } else {
          return '#ddd'
        }
      case 'city':
        if (!citySelected && error) {
          return 'red'
        } else {
          return '#ddd'
        }
      default:
        break
    }
  }

  const colourStyles = (type) => ({
    input: (styles) => ({ ...styles, ...dot(type) }),
    placeholder: (styles) => ({ ...styles, ...dot(type) }),
    singleValue: (styles) => ({ ...styles, ...dot(type) }),
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : styleError(type),
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : styleError(type),
      },
    }),
  })

  useEffect(() => {
    const size = window.innerWidth
    // window.addEventListener('resize', () => {
    //   const newSize = window.innerWidth
    //   if (newSize > 1024) {
    //     setShowForm(true)
    //   } else {
    //     setShowForm(false)
    //   }
    // })
    if (size > 1024) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
  }, [])

  const openSearchMobile = () => {
    if (hotelSelected && checkIn && checkOut) {
      const wyndham = 'https://www.wyndhamhotels.com'
      const lang = getLang(locale)//locale === 'es-ES' ? 'es-xl' : 'pt-br'
      const childrenData = `children=${youngers.toString()}`
      const adultsData = `adults=${adults.toString()}`
      const roomsData = `rooms=${rooms.toString()}`
      const checkInData = new Date(checkIn)
      const checkOutData = new Date(checkOut)
      const checkInDate = `checkInDate=${
        checkInData.getMonth() + 1
      }/${checkInData.getDate()}/${checkInData.getFullYear()}`
      const checkOutDate = `checkOutDate=${
        checkOutData.getMonth() + 1
      }/${checkOutData.getDate()}/${checkInData.getFullYear()}`
      const url = `${wyndham}${lang}${hotelSelected.url}?${childrenData}&${adultsData}&${roomsData}&${checkInDate}&${checkOutDate}`
      
      
      window.open(url, '_blank').focus()
    } else {
      setShowForm(true)
    }
  }

  const openDesktop = async () => {
    if (citySelected && countrySelected && hotelSelected) {
      
      window.open(hrefLink, '_blank').focus()
    } else {
      setError(true)
    }
  }

  return (
    <div className={styles.searchContainer}>
      <h1 className={styles.title}>{title}</h1>
      {showForm ? (
        <Row className={styles.showAnimationMobile}>
          <Col md={4} style={{ marginBottom: '12px' }}>
            <Select
              options={countries}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={countrySelected}
              onChange={(item) => setCountrySelected(item)}
              placeholder={country}
              styles={colourStyles('countries')}
            />
          </Col>
          <Col md={4} style={{ marginBottom: '12px' }}>
            <Select
              options={cityData}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={citySelected}
              onChange={(item) => setCitySelected(item)}
              placeholder={city}
              styles={colourStyles('city')}
            />
          </Col>
          <Col md={4} style={{ marginBottom: '12px' }}>
            <Select
              options={hotelData}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={hotelSelected}
              onChange={(item) => setHotelSelected(item)}
              placeholder={hotel}
              styles={colourStyles('hotel')}
            />
          </Col>
          <Col md={3} style={{ marginBottom: '12px' }}>
            <SelectPerson
              rooms={rooms}
              adults={adults}
              youngers={youngers}
              setRooms={setRooms}
              setAdults={setAdults}
              setYoungers={setYoungers}
              search={search}
              wpInfo={wpInfo}
            />
          </Col>
          <Col md={6} style={{ marginBottom: '12px' }}>
            <SelectDate
              setCheckIn={setCheckIn}
              setCheckOut={setCheckOut}
              monthsShort={monthsShort}
              monthsLong={monthsLong}
              daysLong={daysLong}
              daysShort={daysShort}
            />
          </Col>
          <Col md={3} className={styles.buttonColDesktop}>
            <div className={styles.text} href={hrefLink} onClick={openDesktop}>
              <div className={styles.ctaCard} id = "search-bar-submit">
                <img src={`${wpInfo.assets_url}/icon-search.svg`} width="20" height="20" alt="Search" />
                {btn}
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      <div className={styles.textMobile} onClick={openSearchMobile}>
        <div className={styles.ctaCard}>
          <img
            src={`${wpInfo.assets_url}/icon-search.svg`}
            width="20"
            height="20"
            alt="Search"
            className={styles.imageSearch}
          />
          {btn}
        </div>
      </div>
    </div>
  )
}

export default Search;
