export const searchData = {

  countries: [
    { id: 1, name: 'Argentina' },
    { id: 2, name: 'Bahamas' },
    { id: 3, name: 'Belize' },
    { id: 4, name: 'Bolivia' },
    { id: 5, name: 'Brasil' },
    { id: 6, name: 'Chile' },
    { id: 7, name: 'Colombia' },
    { id: 8, name: 'Costa Rica' },
    { id: 9, name: 'Curacao' },
    { id: 10, name: 'Ecuador' },
    { id: 11, name: 'Guatemala' },
    { id: 12, name: 'Guyana' },
    { id: 13, name: 'Islas Caiman' },
    { id: 14, name: 'Jamaica' },
    { id: 15, name: 'Mexico' },
    { id: 16, name: 'Panama' },
    { id: 17, name: 'Paraguay' },
    { id: 18, name: 'Peru' },
    { id: 19, name: 'Puerto Rico' },
    { id: 20, name: 'Republica Dominicana' },
    { id: 21, name: 'Suriname' },
    { id: 22, name: 'Uruguay' },
    { id: 23, name: 'Venezuela' },
    { id: 24, name: 'Virgin Island' },

  ],

  cities: [
    ///ARG
    { id: 1, countryId: 1, name: 'Buenos Aires' },
    { id: 2, countryId: 1, name: 'Chascomus' },
    { id: 3, countryId: 1, name: 'Cordoba' },
    { id: 4, countryId: 1, name: 'Dolores' },
    { id: 5, countryId: 1, name: 'El Calafate' },
    { id: 6, countryId: 1, name: 'Ezeiza' },
    { id: 7, countryId: 1, name: 'Funes' },
    { id: 8, countryId: 1, name: 'La Plata' },
    { id: 9, countryId: 1, name: 'Piedras Moras' },
    { id: 10, countryId: 1, name: 'Puerto Madryn' },
    { id: 11, countryId: 1, name: 'Rosario' },
    { id: 12, countryId: 1, name: 'Rosario de la Frontera' },
    { id: 13, countryId: 1, name: 'San Francisco' },
    { id: 14, countryId: 1, name: 'San Pedro' },
    { id: 15, countryId: 1, name: 'Ushuaia' },
    { id: 16, countryId: 1, name: 'Vicente Lopez' },
    { id: 17, countryId: 1, name: 'Villa Carlos Paz' },
    { id: 18, countryId: 1, name: 'Yerba Buena' },

    ///BAH
    { id: 1, countryId: 2, name: 'Free port' },

    ///BEL
    { id: 1, countryId: 3, name: 'Ambergris Caye' },
    { id: 2, countryId: 3, name: 'San Pedro' },
    { id: 3, countryId: 3, name: 'Belice City' },

    ///BOL
    { id: 1, countryId: 4, name: 'Santa Cruz de la Sierra' },

    ///BRA
    { id: 1, countryId: 5, name: 'Belo Horizonte' },
    { id: 2, countryId: 5, name: 'Campos Dos Goytacazes' },
    { id: 3, countryId: 5, name: 'Cascavel' },
    { id: 4, countryId: 5, name: 'Fernandopolis' },
    { id: 5, countryId: 5, name: 'Foz Do Iguacu' },
    { id: 6, countryId: 5, name: 'gramado' },
    { id: 7, countryId: 5, name: 'Ilhabela' },
    { id: 8, countryId: 5, name: 'Linhares' },
    { id: 9, countryId: 5, name: 'Macae' },
    { id: 10, countryId: 5, name: 'Manaus' },
    { id: 11, countryId: 5, name: 'Osasco' },
    { id: 12, countryId: 5, name: 'Rio de Janeiro' },
    { id: 13, countryId: 5, name: 'Sao Paulo' },

    ///CHI
    { id: 1, countryId: 6, name: 'Antofagasta' },
    { id: 2, countryId: 6, name: 'Concepcion' },
    { id: 3, countryId: 6, name: 'Quito' },
    { id: 4, countryId: 6, name: 'Rinconada de los Andes' },
    { id: 5, countryId: 6, name: 'Santiago de Chile' },

    ///COL
    { id: 1, countryId: 7, name: 'Barranquilla' },
    { id: 2, countryId: 7, name: 'Bogota' },
    { id: 3, countryId: 7, name: 'Cartagena' },
    { id: 4, countryId: 7, name: 'Medellin' },

    ///C RICA
    { id: 1, countryId: 8, name: 'Tamarindo' },
    { id: 2, countryId: 8, name: 'San Jose' },

    ///CUR
    { id: 1, countryId: 9, name: 'Curacao' },

    ///ECU
    { id: 1, countryId: 10, name: 'Cuenca' },
    { id: 2, countryId: 10, name: 'Guayaquil' },
    { id: 3, countryId: 10, name: 'Manta' },
    { id: 4, countryId: 10, name: 'Montañita' },
    { id: 5, countryId: 10, name: 'Quito' },

    ///GUA
    { id: 1, countryId: 11, name: 'Isla de Flores' },

    ///GUY
    { id: 1, countryId: 12, name: 'Georgetown' },
    
    ///CAI
    { id: 1, countryId: 13, name: 'East End' },

    ///JAM
    { id: 1, countryId: 14, name: 'Montego Bay' },

    ///MEX
    { id: 1, countryId: 15, name: 'Acapulco' },
    { id: 2, countryId: 15, name: 'Aguascalientes' },
    { id: 3, countryId: 15, name: 'Cancun' },
    { id: 4, countryId: 15, name: 'Chetumal' },
    { id: 5, countryId: 15, name: 'Chihuahua' },
    { id: 6, countryId: 15, name: 'Ciudad Juarez' },
    { id: 7, countryId: 15, name: 'Ciudad Obregon' },
    { id: 8, countryId: 15, name: 'Cozumel' },
    { id: 9, countryId: 15, name: 'Cuauhtemoc' },
    { id: 10, countryId: 15, name: 'Culiacan' },
    { id: 11, countryId: 15, name: 'Guadalajara' },
    { id: 12, countryId: 15, name: 'Lazaro Cardenas' },
    { id: 13, countryId: 15, name: 'Leon' },
    { id: 14, countryId: 15, name: 'Los Cabos' },
    { id: 15, countryId: 15, name: 'Mexico City' },
    { id: 16, countryId: 15, name: 'Monterrey' },
    { id: 17, countryId: 15, name: 'Morelia' },
    { id: 18, countryId: 15, name: 'Piedras Negras' },
    { id: 19, countryId: 15, name: 'Playa del Carmen' },
    { id: 20, countryId: 15, name: 'Poza Rica' },
    { id: 21, countryId: 15, name: 'Puebla' },
    { id: 22, countryId: 15, name: 'Queretaro' },
    { id: 23, countryId: 15, name: 'Riviera Cancun' },
    { id: 24, countryId: 15, name: 'Riviera Maya' },
    { id: 25, countryId: 15, name: 'Salamanca' },
    { id: 26, countryId: 15, name: 'San Luis Potosi' },
    { id: 27, countryId: 15, name: 'Toluca' },
    { id: 28, countryId: 15, name: 'Torreon' },
    { id: 29, countryId: 15, name: 'Veracruz' },
    { id: 30, countryId: 15, name: 'Yucatan' },

    ///PAN
    { id: 1, countryId: 16, name: 'Panama City' },

    /// PAR
    { id: 1, countryId: 17, name: 'Asuncion' },
    { id: 2, countryId: 17, name: 'Ciudad del Este' },

    ///PERU
    { id: 1, countryId: 18, name: 'Arequipa' },
    { id: 2, countryId: 18, name: 'Camarja' },
    { id: 3, countryId: 18, name: 'Chiclayo' },
    { id: 4, countryId: 18, name: 'Cusco' },
    { id: 5, countryId: 18, name: 'Lima' },
    { id: 6, countryId: 18, name: 'Piura' },
    { id: 7, countryId: 18, name: 'Pucallpa' },
    { id: 8, countryId: 18, name: 'Trujillo' },
    { id: 9, countryId: 18, name: 'Tumbes' },

    ///P RICO
    { id: 1, countryId: 19, name: 'Isla Verde' },
    { id: 2, countryId: 19, name: 'Mayaguez' },

    ///REP DOM
    { id: 1, countryId: 20, name: 'Bahia Conon' },
    { id: 2, countryId: 20, name: 'Cabarete' },
    { id: 3, countryId: 20, name: 'Cap Cana' },
    { id: 4, countryId: 20, name: 'La romana' },
    { id: 5, countryId: 20, name: 'Puerto Plata' },
    { id: 6, countryId: 20, name: 'Punta Cana' },
    { id: 7, countryId: 20, name: 'Santo Domingo' },

    ///SUR
    { id: 1, countryId: 21, name: 'Paramaribo' },

    ///URU
    { id: 1, countryId: 22, name: 'Colonia' },
    { id: 2, countryId: 22, name: 'Montevideo' },

    ///VEN
    { id: 1, countryId: 23, name: 'Porlamar' },

    ///V. ISL
    { id: 1, countryId: 24, name: 'British Virgin island' },

  ],

  hotels: [

    ///ARG

    //BsAs
    {
      id: 1,
      cityId: 1,
      countryId: 1,
      name: 'Esplendor By Wyndham Buenos Aires',
      url: '/esplendor/buenos-aires-argentina/hotel-esplendor-buenos-aires/rooms-rates',
    },
    {
      id: 2,
      cityId: 1,
      countryId: 1,
      name: 'Dazzler By Wyndham Buenos Aires Recoleta',
      url: '/dazzler/buenos-aires-argentina/dazzler-recoleta/rooms-rates',
    }, 
    {
      id: 3,
      cityId: 1,
      countryId: 1,
      name: 'Dazzler By Wyndham Buenos Aires San Martin',
      url: '/dazzler/buenos-aires-argentina/dazzler-san-martin/rooms-rates',
    },
    {
      id: 4,
      cityId: 1,
      countryId: 1,
      name: 'Ramada By Wyndham Buenos Aires Centro',
      url: '/ramada/buenos-aires-argentina/ramada-by-wyndham-buenos-aires-centro/rooms-rates',
    },
    {
      id: 5,
      cityId: 1,
      countryId: 1,
      name: 'Esplendor by Wyndham Buenos Aires Tango',
      url: '/esplendor/buenos-aires-argentina/esplendor-buenos-aires-tango/rooms-rates',
    },
    {
      id: 6,
      cityId: 1,
      countryId: 1,
      name: 'Dazzler By Wyndham Buenos Aires Palermo',
      url: '/dazzler/buenos-aires-argentina/dazzler-palermo-buenos-aires/rooms-rates',
    },
    {
      id: 7,
      cityId: 1,
      countryId: 1,
      name: 'Dazzler By Wyndham Buenos Aires Polo',
      url: '/dazzler/buenos-aires-argentina/dazzler-polo-hotel-buenos-aires/rooms-rates',
    },
    {
      id: 8,
      cityId: 1,
      countryId: 1,
      name: 'Dazzler by Wyndham Buenos Aires Maipú',
      url: '/dazzler/buenos-aires-argentina/dazzler-maipu/rooms-rates',
    },
    {
      id: 9,
      cityId: 1,
      countryId: 1,
      name: 'Esplendor By Wyndham Buenos Aires Plaza Francia',
      url: '/esplendor/buenos-aires-argentina/esplendor-buenos-aires-plaza-francia/rooms-rates',
    },
    {
      id: 10,
      cityId: 1,
      countryId: 1,
      name: 'Howard Johnson Plaza By Wyndham Florida Street',
      url: '/hojo/buenos-aires-argentina/howard-johnson-plaza-buenos-aires-florida-street/rooms-rates',
    },
    {
      id: 11,
      cityId: 1,
      countryId: 1,
      name: 'Howard Johnson Plaza By Wyndham Buenos Aires',
      url: '/hojo/buenos-aires-argentina/howard-johnson-plaza-buenos-aires/rooms-rates',
    },
    {
      id: 12,
      cityId: 1,
      countryId: 1,
      name: 'Wyndham Nordelta Tigre Buenos Aires',
      url: '/wyndham/tigre-argentina/wyndham-nordelta-tigre-buenos-aires/rooms-rates',
    },

    //Chascomus
    {
      id: 13,
      cityId: 2,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Chascomus',
      url: '/hojo/chascomus-argentina/howard-johnson-chascomus/rooms-rates',
    }, 

    //Cordoba
    {
      id: 14,
      cityId: 3,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Cordoba',
      url: '/hojo/cordoba-argentina/howard-johnson-cordoba/rooms-rates',
    },

    //Dolores
    {
      id: 15,
      cityId: 4,
      countryId: 1,
      name: 'Days Inn by Wyndham Dolores Hotel Termal',
      url: '/days-inn/dolores-argentina/days-inn-dolores-hotel-termal/rooms-rates',
    },

    //El Calafate
    {
      id: 16,
      cityId: 5,
      countryId: 1,
      name: 'Esplendor By Wyndham El Calafate',
      url: '/esplendor/el-calafate-argentina/esplendor-el-calafate/rooms-rates',
    },

    //Ezeiza
    {
      id: 17,
      cityId: 6,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Ezeiza Convention Center',
      url: '/hojo/ezeiza-argentina/howard-johnson-ezeiza-convention-center/rooms-rates',
    },

    //Funes
    {
      id: 18,
      cityId: 7,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Funes',
      url: '/hojo/funes-argentina/howard-johnson-funes/rooms-rates',
    },

    //La Plata
    {
      id: 19,
      cityId: 8,
      countryId: 1,
      name: 'Dazzler by Wyndham La Plata',
      url: '/dazzler/la-plata/dazzler-la-plata/rooms-rates',
    },
    {
      id: 20,
      cityId: 8,
      countryId: 1,
      name: 'Days Inn & Suites By Wyndham La Plata',
      url: '/days-inn/la-plata-argentina/days-inn-and-suites-by-wyndham-la-plata/rooms-rates',
    },
    {
      id: 21,
      cityId: 8,
      countryId: 1,
      name: 'Howard Johnson By Wyndham La Plata',
      url: '/hojo/la-plata-argentina/howard-johnson-laplata/rooms-rates',
    },

    //Piedras Moras
    {
      id: 22,
      cityId: 9,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Piedras Moras',
      url: '/hojo/almafuerte-argentina/howard-johnson-piedras-moras/rooms-rates',
    },

    //Puerto Madryn
    {
      id: 23,
      cityId: 10,
      countryId: 1,
      name: 'Dazzler By Wyndham Puerto Madryn',
      url: '/dazzler/puerto-madryn-argentina/dazzler-puerto-madryn/rooms-rates',
    },
    
    //Rosario
    {
      id: 24,
      cityId: 11,
      countryId: 1,
      name: 'Dazzler By Wyndham Rosario',
      url: '/dazzler/rosario-argentina/dazzler-rosario/rooms-rates',
    },
    {
      id: 25,
      cityId: 11,
      countryId: 1,
      name: 'Esplendor By Wyndham Savoy Rosario',
      url: '/esplendor/rosario-argentina/esplendor-savoy-rosario/rooms-rates',
    },

    //Rosario de la Frontera
    {
      id: 26,
      cityId: 12,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Rosario De La Frontera',
      url: '/hojo/rosario-de-la-frontera-argentina/howard-johnson-rosario-de-la-frontera/rooms-rates',
    },

    //San Francisco
    {
      id: 27,
      cityId: 13,
      countryId: 1,
      name: 'Howard Johnson By Wyndham San Francisco Resort & Conv Center',
      url: '/hojo/san-francisco-argentina/howard-johnson-san-francisco-resort-and-conv-center/rooms-rates',
    },

    //San Pedro
    {
      id: 28,
      cityId: 14,
      countryId: 1,
      name: 'Howard Johnson By Wyndham San Pedro',
      url: '/hojo/san-pedro-argentina/howard-johnson-san-pedro/rooms-rates',
    },
    {
      id: 29,
      cityId: 14,
      countryId: 1,
      name: 'Howard Johnson by Wyndham San Pedro Resort & Marina',
      url: '/hojo/san-pedro-argentina/howard-johnson-san-pedro-resort-and-marina/rooms-rates',
    },

    //Ushuaia
    {
      id: 30,
      cityId: 15,
      countryId: 1,
      name: 'Wyndham Garden Ushuaia Hotel Del Glaciar',
      url: '/wyndham-garden/ushuaia-argentina/wyndham-garden-ushuaia-hotel-del-glaciar/rooms-rates',
    },

    //Vicente Lopez
    {
      id: 31,
      cityId: 16,
      countryId: 1,
      name: 'Ramada By Wyndham Buenos Aires Vicente Lopez',
      url: '/ramada/vicente-lopez-argentina/ramada-buenos-aires-vicente-lopez/rooms-rates',
    },

    //Villa Carlos Paz
    {
      id: 32,
      cityId: 17,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Villa Carlos Paz',
      url: '/hojo/villa-carlos-paz-argentina/howard-johnson-villa-carlos-paz/rooms-rates',
    },

    //Yerba Buena
    {
      id: 33,
      cityId: 18,
      countryId: 1,
      name: 'Howard Johnson By Wyndham Yerba Buena',
      url: '/hojo/yerba-buena-argentina/howard-johnson-yerba-buena/rooms-rates',
    },

    ///BAH

    //Free port
    {
      id: 34,
      cityId: 1,
      countryId: 2,
      name: 'Viva Wyndham Fortuna Beach - All-Inclusive Resort',
      url: '/wyndham/freeport-bahamas/viva-wyndham-fortuna-beach-all-inclusive-resort/rooms-rates',
    },

    ///BEL

    //Ambergris Caye
    {
      id: 35,
      cityId: 1,
      countryId: 3,
      name: 'Costa Blu Beach Resort, Trademark Collection By Wyndham',
      url: '/trademark/san-pedro-town-belize/costa-blu-beach-resort-trademark-collection/rooms-rates',
    },

    {
      id: 36,
      cityId: 2,
      countryId: 3,
      name: 'Turtle Island Beach Resort, Trademark Collection by Wyndham',
      url: '/trademark/san-pedro-belize/turtle-island-beach-resort-trademark-collection/rooms-rates',
    },

    {
      id: 37,
      cityId: 3,
      countryId: 3,
      name: 'Ramada By Wyndham Princess Belize City',
      url: '/ramada/belize-city-belize/ramada-belize-city-princess/rooms-rates',
    },

    ///BOL

    //Santa Cruz de la Sierra
    {
      id: 38,
      cityId: 1,
      countryId: 4,
      name: 'Tryp By Wyndham Santa Cruz',
      url: '/tryp/santa-cruz-de-la-sierra-bolivia/tryp-santa-cruz/rooms-rates',
    },

    ///BRA

    //Belo Horizonte
    {
      id: 39,
      cityId: 1,
      countryId: 5,
      name: 'Ramada Encore By Wyndham Belo Horizonte Minascasa',
      url: '/ramada/belo-horizonte-brazil/ramada-encore-belo-horizonte-minascasa/rooms-rates',
    },
    {
      id: 40,
      cityId: 1,
      countryId: 5,
      name: 'Ouro Minas Hotel Belo Horizonte, Dolce by Wyndham',
      url: '/dolce/belo-horizonte-brazil/ouro-minas-hotel-belo-horizonte-dolce/rooms-rates',
    },
    {
      id: 41,
      cityId: 1,
      countryId: 5,
      name: 'Ramada By Wyndham Lagoa Santa Intl Airport Belo Horizonte',
      url: '/ramada/lagoa-santa-brazil/ramada-lagoa-santa-international-airport/rooms-rates',
    },
    {
      id: 42,
      cityId: 1,
      countryId: 5,
      name: 'Ramada by Wyndham Belo Horizonte Lourdes',
      url: '/ramada/belo-horizonte-brazil/ramada-belo-horizonte-lourdes/rooms-rates',
    },
    {
      id: 43,
      cityId: 1,
      countryId: 5,
      name: 'Ramada Encore By Wyndham Belo Horizonte Luxemburgo',
      url: '/ramada/belo-horizonte-brazil/ramada-encore-virginia-luxemburgo-belo/rooms-rates',
    },

    //Campos Dos Goytacazes

    {
      id: 44,
      cityId: 2,
      countryId: 5,
      name: 'Ramada By Wyndham Campos Dos Goytacazes Hotel & Suites',
      url: '/ramada/campos-dos-goytacazes-brazil/ramada-campos-pelinca/rooms-rates',
    },

    //Cascavel

    {
      id: 45,
      cityId: 3,
      countryId: 5,
      name: 'Days Inn by Wyndham Cascavel',
      url: '/days-inn/cascavel-brazil/days-inn-cascavel/rooms-rates',
    },

    //Fernandopolis

    {
      id: 46,
      cityId: 4,
      countryId: 5,
      name: 'Ramada Encore by Wyndham Fernandopolis',
      url: '/ramada/fernandopolis-brazil/ramada-encore-fernandopolis/rooms-rates',
    },

    //Foz Do Iguacu

    {
      id: 47,
      cityId: 5,
      countryId: 5,
      name: 'Wyndham Foz Do Iguacu',
      url: '/wyndham/foz-do-iguacu-brazil/wyndham-foz-do-iguacua/rooms-rates',
    },

    //gramado

    {
      id: 48,
      cityId: 6,
      countryId: 5,
      name: 'Wyndham Gramado Termas Resort & Spa',
      url: '/wyndham/gramado-brazil/wyndham-gramado-termas-resort-and-spa/rooms-rates',
    },

    //Ilhabela

    {
      id: 49,
      cityId: 7,
      countryId: 5,
      name: 'Wyndham Ilhabela',
      url: '/wyndham/ilhabela-brazil/wyndham-ilhabela-casa-di-sirena/rooms-rates',
    },

    //Linhares

    {
      id: 50,
      cityId: 8,
      countryId: 5,
      name: 'Days Inn By Wyndham Linhares',
      url: '/days-inn/linhares-brazil/days-inn-linhares/rooms-rates',
    },

    //Macae

    {
      id: 51,
      cityId: 9,
      countryId: 5,
      name: 'Ramada By Wyndham Macae Hotel & Suites',
      url: '/ramada/macae-brazil/ramada-hotel-and-suites-macae/rooms-rates',
    },

    //Manaus

    {
      id: 52,
      cityId: 10,
      countryId: 5,
      name: 'Tryp By Wyndham Manaus',
      url: '/tryp/manaus-brazil/tryp-manaus/rooms-rates',
    },
    {
      id: 53,
      cityId: 10,
      countryId: 5,
      name: 'Ramada by Wyndham Manaus Torres Center',
      url: '/ramada/manaus-brazil/ramada-manaus-torres-center/rooms-rates',
    },

    //Osasco
    {
      id: 54,
      cityId: 11,
      countryId: 5,
      name: 'Ramada Hotel & Suites By Wyndham Osasco',
      url: '/ramada/osasco-brazil/ramada-encore-osasco/rooms-rates',
    },

    //Rio de Janeiro

    {
      id: 55,
      cityId: 12,
      countryId: 5,
      name: 'TRYP by Wyndham Rio de Janeiro Barra Parque Olimpico',
      url: '/tryp/rio-de-janeiro-brazil/tryp-rio-de-janeiro-barra-parque-olimpico/rooms-rates',
    },
    {
      id: 56,
      cityId: 12,
      countryId: 5,
      name: 'Ramada By Wyndham Rio De Janeiro Recreio',
      url: '/ramada/rio-de-janeiro-brazil/ramada-rio-de-janeiro/rooms-rates',
    },
    {
      id: 57,
      cityId: 12,
      countryId: 5,
      name: 'Ramada Encore By Wyndham Rio De Janeiro Ribalta',
      url: '/ramada/rio-de-janeiro-brazil/ramada-encore-ribalta-rio-de-janeiro/rooms-rates',
    },

    //Sao Paulo

    {
      id: 58,
      cityId: 13,
      countryId: 5,
      name: 'Wyndham Garden Sao Paulo Convention Nortel',
      url: '/wyndham-garden/sao-paulo-brazil/wyndham-garden-sao-paulo-convention-nortel/rooms-rates',
    },
    {
      id: 59,
      cityId: 13,
      countryId: 5,
      name: 'Ramada Encore By Wyndham Sao Paulo Tiradentes',
      url: '/ramada/sao-paulo-brazil/ramada-encore-sao-paulo-tiradentes/rooms-rates',
    },
    {
      id: 60,
      cityId: 13,
      countryId: 5,
      name: 'Ramada Encore By Wyndham Berrini',
      url: '/ramada/sao-paulo-brazil/ramada-encore-berrini/rooms-rates',
    },
    {
      id: 61,
      cityId: 13,
      countryId: 5,
      name: 'Wyndham Sao Paulo Berrini',
      url: '/wyndham/sao-paulo-brazil/wyndham-sao-paulo-berrini/rooms-rates',
    },
    {
      id: 62,
      cityId: 13,
      countryId: 5,
      name: 'Wyndham Sao Paulo Paulista',
      url: '/wyndham/sao-paulo-brazil/wyndham-sao-paulo-paulista/rooms-rates',
    },
    {
      id: 63,
      cityId: 13,
      countryId: 5,
      name: 'Wyndham Garden Ribeirao Preto Convention',
      url: '/wyndham-garden/ribeirao-preto-brazil/wyndham-garden-ribeirao-preto-convention/rooms-rates',
    },

    ///CHI

    //Antofagasta

    {
      id: 64,
      cityId: 1,
      countryId: 6,
      name: 'Wyndham Garden Antofagasta Pettra',
      url: '/wyndham-garden/antofagasta-chile/wyndham-garden-antofagasta-pettra/rooms-rates',
    },

    //Concepcion

    {
      id: 65,
      cityId: 2,
      countryId: 6,
      name: 'Wyndham Concepción Pettra',
      url: '/wyndham/concepcion-chile/wyndham-concepcion-pettra/rooms-rates',
    },

    //Quito

    {
      id: 66,
      cityId: 3,
      countryId: 6,
      name: 'Wyndham Quito Airport',
      url: '/wyndham/quito-ecuador/wyndham-quito-airport/rooms-rates',
    },

    //Rinconada de los Andes

    {
      id: 67,
      cityId: 4,
      countryId: 6,
      name: 'Howard Johnson By Wyndham Rinconada De Los Andes',
      url: '/hojo/rinconada-de-los-andes-chile/howard-johnson-rinconada-de-los-andes/rooms-rates',
    },

    //Santiago de Chile

    {
      id: 68,
      cityId: 5,
      countryId: 6,
      name: 'Wyndham Santiago Pettra',
      url: '/wyndham/santiago-chile/wyndham-santiago-pettra/rooms-rates',
    },
    {
      id: 69,
      cityId: 5,
      countryId: 6,
      name: 'La Quinta By Wyndham Santiago Aeropuerto',
      url: '/laquinta/santiago-chile/la-quinta-hotel-santiago-aeropuerto/rooms-rates',
    },

    ///COL

    //Barranquilla

    {
      id: 70,
      cityId: 1,
      countryId: 7,
      name: 'Howard Johnson By Wyndham Barranquilla Versalles',
      url: '/hojo/barranquilla-colombia/howard-johnson-hotel-versalles-barranquilla/rooms-rates',
    },
    {
      id: 71,
      cityId: 1,
      countryId: 7,
      name: 'Wyndham Garden Barranquilla',
      url: '/wyndham-garden/barranquilla-colombia/wyndham-garden-barranquilla/rooms-rates',
    },

    //Bogota

    {
      id: 72,
      cityId: 2,
      countryId: 7,
      name: 'Ramada Encore by Wyndham Bogota Zona Franca',
      url: '/ramada/bogota-colombia/ramada-encore-bogota-zona-franca/rooms-rates',
    },
    {
      id: 73,
      cityId: 2,
      countryId: 7,
      name: 'Tryp By Wyndham Bogota Embajada',
      url: '/tryp/bogota-colombia/tryp-bogota-embajada/rooms-rates',
    },
    {
      id: 74,
      cityId: 2,
      countryId: 7,
      name: 'Wyndham Bogota',
      url: '/wyndham/bogota-colombia/wyndham-bogota/rooms-rates',
    },

    //Cartagena

    {
      id: 75,
      cityId: 3,
      countryId: 7,
      name: 'Wyndham Garden Cartagena',
      url: '/wyndham-garden/cartagena-colombia/wyndham-garden-cartagena/rooms-rates',
    },

    //Medellin

    {
      id: 76,
      cityId: 4,
      countryId: 7,
      name: 'La Quinta By Wyndham Medellin',
      url: '/laquinta/medellin-colombia/lq-hotel-medellin/rooms-rates',
    },
    {
      id: 77,
      cityId: 4,
      countryId: 7,
      name: 'TRYP by Wyndham Medellin',
      url: '/tryp/medellin-colombia/tryp-medellin/rooms-rates',
    },

    ///C RICA

    //Tamarindo

    {
      id: 78,
      cityId: 1,
      countryId: 8,
      name: 'Wyndham Tamarindo',
      url: '/wyndham/tamarindo-costa-rica/wyndham-tamarindo/rooms-rates',
    },

    //San Jose

    {
      id: 79,
      cityId: 2,
      countryId: 8,
      name: 'Wyndham Garden San Jose Escazu',
      url: '/wyndham-garden/san-jose-costa-rica/wyndham-garden-san-jose-escazu/rooms-rates',
    },
    {
      id: 80,
      cityId: 2,
      countryId: 8,
      name: 'Wyndham San Jose Herradura Hotel & Convention Center',
      url: '/wyndham/san-jose-costa-rica/wyndham-san-jose-herradura-hotel-convention-center/rooms-rates',
    },
    {
      id: 81,
      cityId: 2,
      countryId: 8,
      name: 'Tryp By Wyndham San Jose Sabana',
      url: '/tryp/san-jose-costa-rica/tryp-by-wyndham-san-jose-sabana/rooms-rates',
    },

    ///CUR

    //Curacao

    {
      id: 82,
      cityId: 1,
      countryId: 9,
      name: 'Kunuku Resort All Inclusive Curacao, Trademark by Wyndham',
      url: '/trademark/willemstad-curacao/kunuku-resort-all-inclusive-curacao/rooms-rates',
    },

    ///ECU

    //Cuenca

    {
      id: 83,
      cityId: 1,
      countryId: 10,
      name: 'Tryp By Wyndham Cuenca Zahir',
      url: '/tryp/cuenca-ecuador/tryp-cuenca-zahir/rooms-rates',
    },

    //Guayaquil

    {
      id: 84,
      cityId: 2,
      countryId: 10,
      name: 'Wyndham Guayaquil',
      url: '/wyndham/guayaquil-ecuador/wyndham-guayaquil/rooms-rates',
    },
    {
      id: 85,
      cityId: 2,
      countryId: 10,
      name: 'TRYP by Wyndham Guayaquil',
      url: '/tryp/guayaquil-ecuador/tryp-guayaquil/rooms-rates',
    },
    {
      id: 86,
      cityId: 2,
      countryId: 10,
      name: 'Wyndham Garden Guayaquil',
      url: '/wyndham-garden/guayaquil-ecuador/wyndham-garden-guayaquil/rooms-rates',
    },

    //Manta

    {
      id: 87,
      cityId: 3,
      countryId: 10,
      name: 'Wyndham Manta Sail Plaza Hotel And Convention Center',
      url: '/wyndham/manta-ecuador/wyndham-manta-sail-plaza-hotel-and-convention-center/rooms-rates',
    },

    //Montañita

    {
      id: 88,
      cityId: 4,
      countryId: 10,
      name: 'Howard Johnson by Wyndham Montañita',
      url: '/hojo/montanita-ecuador/howard-johnson-montanita/rooms-rates',
    },

    //Quito

    {
      id: 89,
      cityId: 5,
      countryId: 10,
      name: 'Wyndham Garden Quito',
      url: '/wyndham-garden/quito-ecuador/wyndham-garden-quito/rooms-rates',
    },

    ///GUA

    //Isla de Flores

    {
      id: 90,
      cityId: 1,
      countryId: 11,
      name: 'Ramada By Wyndham Tikal Isla De Flores',
      url: '/ramada/isla-de-flores-guatemala/ramada-tikal-isla-de-flores/rooms-rates?',
    },

    ///GUY

    //Georgetown

    {
      id: 91,
      cityId: 1,
      countryId: 12,
      name: 'Ramada By Wyndham Princess Georgetown',
      url: '/ramada/georgetown-guyana/ramada-georgetown-princess-hotel/rooms-rates',
    },

    ///CAI

    //East End

    {
      id: 92,
      cityId: 1,
      countryId: 13,
      name: 'Wyndham Reef Resort Grand Cayman',
      url: '/wyndham/east-end-cayman-islands/wyndham-reef-resort-grand-cayman/rooms-rates',
    },

    ///JAM

    //Montego Bay

    {
      id: 93,
      cityId: 1,
      countryId: 14,
      name: 'Grand Palladium Jamaica, All Inclusive Resort & Spa',
      url: '/registry-collection/montego-bay-jamaica/grand-palladium-jamaica-all-inclusive-resort-spa/rooms-rates',
    },

    ///MEX

    //Acapulco

    {
      id: 94,
      cityId: 1,
      countryId: 15,
      name: 'Ramada by Wyndham Acapulco',
      url: '/ramada/acapulco-mexico/ramada-acapulco-hotel-suites/rooms-rates',
    },

    //Aguascalientes

    {
      id: 95,
      cityId: 2,
      countryId: 15,
      name: 'Ramada Encore By Wyndham Aguascalientes',
      url: '/ramada/aguascalientes-mexico/ramada-encore-aguascalientes/rooms-rates',
    },
    {
      id: 96,
      cityId: 2,
      countryId: 15,
      name: 'La Quinta By Wyndham Aguascalientes',
      url: '/laquinta/aguascalientes-mexico/lq-hotel-aguascalientes/rooms-rates',
    },
    {
      id: 97,
      cityId: 2,
      countryId: 15,
      name: 'Wyndham Garden Aguascalientes Hotel & Casino',
      url: '/wyndham-garden/aguascalientes-mexico/wyndham-garden-aguascalientes-hotel-and-casino/rooms-rates',
    },

    //Cancun

    {
      id: 98,
      cityId: 3,
      countryId: 15,
      name: 'Wyndham Garden Cancun Downtown',
      url: '/wyndham-garden/cancun-mexico/wyndham-garden-cancun-downtown/rooms-rates',
    },    

    //Chetumal

    {
      id: 99,
      cityId: 4,
      countryId: 15,
      name: 'TRYP by Wyndham Chetumal',
      url: '/tryp/chetumal-mexico/tryp-chetumal/rooms-rates',
    },   

    //Chihuahua

    {
      id: 100,
      cityId: 5,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham Chihuahua',
      url: '/microtel/chihuahua-mexico/microtel-inn-suites-chihuahua/rooms-rates',
    },
    {
      id: 101,
      cityId: 5,
      countryId: 15,
      name: 'Ramada Encore By Wyndham Chihuahua',
      url: '/ramada/chihuahua-mexico/ramada-encore-chihuahua/rooms-rates',
    },   

    //Ciudad Juarez

    {
      id: 102,
      cityId: 6,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham Ciudad Juarez/Us Consulate',
      url: '/microtel/ciudad-juarez-mexico/microtel-inn-ciudad-juarez-by-us-consulate/rooms-rates',
    },
    {
      id: 103,
      cityId: 6,
      countryId: 15,
      name: 'La Quinta By Wyndham Ciudad Juarez Near Us Consulate',
      url: '/laquinta/ciudad-juarez-mexico/lq-hotel-cd-juarez-near-us-consulate/rooms-rates',
    },   

    //Ciudad Obregon

    {
      id: 104,
      cityId: 7,
      countryId: 15,
      name: 'Wyndham Garden Ciudad Obregon',
      url: '/wyndham-garden/ciudad-obregon-mexico/wyndham-garden-ciudad-obregon/rooms-rates',
    },   

    //Cozumel
    {
      id: 105,
      cityId: 8,
      countryId: 15,
      name: 'Cozumel Hotel & Resort, Trademark Collection by Wyndham',
      url: '/trademark/cozumel-mexico/cozumel-hotel-and-resort/rooms-rates',
    }, 
    
    //Cuauhtemoc

    {
      id: 106,
      cityId: 9,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham Cuauhtemoc',
      url: '/microtel/chihuahua-mexico/microtel-inn-and-suites-by-wyndham-cuauhtemoc/rooms-rates',
    }, 

    //Culiacan

    {
      id: 107,
      cityId: 10,
      countryId: 15,
      name: 'Wyndham Executivo Culiacan',
      url: '/wyndham/culiacan-mexico/wyndham-executivo-culiacan/rooms-rates',
    }, 
    {
      id: 108,
      cityId: 10,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham Culiacan',
      url: '/microtel/culiacan-mexico/microtel-inn-and-suites-culiacan/rooms-rates',
    }, 
    {
      id: 109,
      cityId: 10,
      countryId: 15,
      name: 'Ramada By Wyndham Hola Culiacan',
      url: '/ramada/culiacan-mexico/ramada-hola-culiacan/rooms-rates',
    }, 
    
    //Guadalajara

    {
      id: 110,
      cityId: 11,
      countryId: 15,
      name: 'Wyndham Garden Guadalajara Acueducto',
      url: '/wyndham-garden/guadalajara-mexico/wyndham-garden-guadalajara-andares/rooms-rates',
    }, 
    {
      id: 111,
      cityId: 11,
      countryId: 15,
      name: 'Ramada Encore by Wyndham Guadalajara Aeropuerto',
      url: '/ramada/guadalajara-mexico/ramada-encore-guadalajara-aeropuerto/rooms-rates',
    }, 
    {
      id: 112,
      cityId: 11,
      countryId: 15,
      name: 'Wyndham Garden Guadalajara Expo',
      url: '/wyndham-garden/guadalajara-mexico/wyndham-garden-guadalajara-expo/rooms-rates',
    }, 

    //Lazaro Cardenas

    {
      id: 113,
      cityId: 12,
      countryId: 15,
      name: 'Baymont By Wyndham Lazaro Cardenas',
      url: '/baymont/lazaro-cardenas-mexico/baymont-inn-and-suites-lazaro-cardenas/rooms-rates',
    }, 

    //Leon

    {
      id: 114,
      cityId: 13,
      countryId: 15,
      name: 'Howard Johnson By Wyndham Guanajuato Leon Avenida',
      url: '/hojo/leon-mexico/howard-johnson-leon-avenida/rooms-rates',
    }, 
    {
      id: 115,
      cityId: 13,
      countryId: 15,
      name: 'Ramada Plaza By Wyndham Leon',
      url: '/ramada/leon-mexico/ramada-plaza-leon/rooms-rates',
    }, 

    //Los Cabos

    {
      id: 116,
      cityId: 14,
      countryId: 15,
      name: 'Los Cabos Golf Resort, Trademark Collection by Wyndham',
      url: '/trademark/cabo-san-lucas-mexico/los-cabos-golf-resort-trademark-collection/rooms-rates',
    }, 

    //Mexico City

    {
      id: 117,
      cityId: 15,
      countryId: 15,
      name: 'Bel Air Business Mexico City WTC, Trademark by Wyndham',
      url: '/trademark/mexico-city-mexico/bel-air-business-mexico-city-wtc-trademark/rooms-rates',
    }, 
    {
      id: 118,
      cityId: 15,
      countryId: 15,
      name: 'Bel Air Unique Mexico City WTC, Trademark by Wyndham',
      url: '/trademark/mexico-city-mexico/bel-air-unique-mexico-city-wtc-trademark/rooms-rates',
    }, 
    {
      id: 119,
      cityId: 15,
      countryId: 15,
      name: 'Wyndham Garden Mexico City Polanco',
      url: '/wyndham-garden/mexico-city-mexico/wyndham-garden-mexico-city-polanco/rooms-rates',
    }, 
    {
      id: 120,
      cityId: 15,
      countryId: 15,
      name: 'Ramada By Wyndham Mexico City Reforma',
      url: '/ramada/mexico-city-mexico/ramada-reforma-mexico-city/rooms-rates',
    }, 
    {
      id: 121,
      cityId: 15,
      countryId: 15,
      name: 'Tryp By Wyndham Mexico City World Trade Center Area',
      url: '/tryp/mexico-city-mexico/howard-johnson-by-wyndham-hannibal/rooms-rates',
    }, 
    {
      id: 122,
      cityId: 15,
      countryId: 15,
      name: 'Ramada by Wyndham Mexico City Santa Fe',
      url: '/ramada/mexico-city-mexico/ramada-mexico-city-santa-fe/rooms-rates',
    }, 

    //Monterrey

    {
      id: 123,
      cityId: 16,
      countryId: 15,
      name: 'Wyndham Garden Monterrey Norte',
      url: '/wyndham-garden/monterrey-mexico/wyndham-garden-monterrey-norte/rooms-rates',
    }, 
    {
      id: 124,
      cityId: 16,
      countryId: 15,
      name: 'Wyndham Garden Monterrey Valle Real',
      url: '/wyndham-garden/monterrey-mexico/wyndham-garden-monterrey-valle-real/rooms-rates',
    }, 
    {
      id: 125,
      cityId: 16,
      countryId: 15,
      name: 'Ramada Encore by Wyndham Monterrey Apodaca Zona Aeropuerto',
      url: '/ramada/monterrey-mexico/ramada-encore-onterrey-apodaca-zona-aeropuerto/rooms-rates',
    }, 
    {
      id: 126,
      cityId: 16,
      countryId: 15,
      name: 'CHN Hotel Monterrey Aeropuerto, Trademark by Wyndham',
      url: '/trademark/monterrey-mexico/chn-hotel-monterrey-aeropuerto-trademark/rooms-rates',
    }, 
    {
      id: 127,
      cityId: 16,
      countryId: 15,
      name: 'CHN Hotel Monterrey Norte, Trademark by Wyndham',
      url: '/trademark/monterrey-mexico/chn-hotel-monterrey-norte/rooms-rates',
    }, 
    {
      id: 128,
      cityId: 16,
      countryId: 15,
      name: 'CHN Hotel Monterrey Centro, Trademark by Wyndham',
      url: '/trademark/monterrey-mexico/chn-hotel-monterrey-centro-trademark/rooms-rates',
    }, 

    //Morelia

    {
      id: 129,
      cityId: 17,
      countryId: 15,
      name: 'Howard Johnson By Wyndham Morelia Calle Real',
      url: '/hojo/morelia-michoacan-mexico/howard-johnson-calle-real-morelia/rooms-rates',
    }, 

    //Piedras Negras

    {
      id: 130,
      cityId: 18,
      countryId: 15,
      name: 'Days Inn By Wyndham Piedras Negras',
      url: '/days-inn/piedras-negras-mexico/days-inn-piedras-negras/rooms-rates',
    }, 

    //Playa del Carmen

    {
      id: 131,
      cityId: 19,
      countryId: 15,
      name: 'Wyndham Garden Playa Del Carmen',
      url: '/wyndham-garden/playa-del-carmen-mexico/wy-garden-playa-del-carmen/rooms-rates',
    }, 
    {
      id: 132,
      cityId: 19,
      countryId: 15,
      name: 'Viva Wyndham Azteca - All-Inclusive Resort',
      url: '/wyndham/playa-del-carmen-mexico/viva-wyndham-azteca-all-inclusive-resort/rooms-rates',
    }, 
    {
      id: 133,
      cityId: 19,
      countryId: 15,
      name: 'Viva Wyndham Maya - All-Inclusive Resort',
      url: '/wyndham/playa-del-carmen-mexico/viva-wyndham-maya-all-inclusive-resort/rooms-rates',
    }, 
    {
      id: 134,
      cityId: 19,
      countryId: 15,
      name: 'Acanto Playa Del Carmen, Trademark Collection By Wyndham',
      url: '/trademark/playa-del-carmen-mexico/acanto-playa-del-carmen/rooms-rates',
    }, 

    //Poza Rica

    {
      id: 135,
      cityId: 20,
      countryId: 15,
      name: 'La Quinta By Wyndham Poza Rica',
      url: '/laquinta/poza-rica-mexico/lq-hotel-poza-rica/rooms-rates',
    },     

    //Puebla

    {
      id: 136,
      cityId: 21,
      countryId: 15,
      name: 'Ramada Encore By Wyndham Puebla',
      url: '/ramada/puebla-mexico/ramada-encore-puebla-mexico/rooms-rates',
    },   
    {
      id: 137,
      cityId: 21,
      countryId: 15,
      name: 'La Quinta By Wyndham Puebla Palmas Angelopolis',
      url: '/laquinta/puebla-mexico/lq-hotel-puebla-palmas-angelopolis/rooms-rates',
    }, 
    {
      id: 138,
      cityId: 21,
      countryId: 15,
      name: 'Wyndham Puebla Angelopolis',
      url: '/wyndham/puebla-mexico/wyndham-puebla-angelopolis/rooms-rates',
    }, 

    //Queretaro

    {
      id: 139,
      cityId: 22,
      countryId: 15,
      name: 'Ramada Encore By Wyndham Queretaro',
      url: '/ramada/queretaro-mexico/ramada-encore-queretaro/rooms-rates',
    }, 

    //Riviera Cancun

    {
      id: 140,
      cityId: 23,
      countryId: 15,
      name: 'Grand Residences Riviera Cancun, a Registry Collection Hotel',
      url: '/registry-collection/riviera-cancun-mexico/grand-residences-riviera-cancun-a-registry-collection-hotel/rooms-rates',
    }, 

    //Riviera Maya

    {
      id: 141,
      cityId: 24,
      countryId: 15,
      name: 'Grand Palladium Colonial Riviera Maya, All Inclusive Resort',
      url: '/registry-collection/riviera-maya-mexico/grand-palladium-colonial-riviera-maya-all-inclusive-resort/rooms-rates',
    }, 

    //Salamanca

    {
      id: 142,
      cityId: 25,
      countryId: 15,
      name: 'Bel Air Business Salamanca, Trademark by Wyndham',
      url: '/trademark/salamanca-mexico/bel-air-business-salamanca-trademark/rooms-rates',
    }, 

    //San Luis Potosi

    {
      id: 143,
      cityId: 26,
      countryId: 15,
      name: 'Tryp By Wyndham San Luis Potosi',
      url: '/tryp/san-luis-potosi-mexico/tryp-by-wyndham-san-luis-potosi/rooms-rates',
    }, 
    {
      id: 144,
      cityId: 26,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham San Luis Potosi',
      url: '/microtel/san-luis-potosi-mexico/microtel-inn-and-suites-by-wyndham-san-luis-potosi/rooms-rates',
    }, 

    //Toluca

    {
      id: 145,
      cityId: 27,
      countryId: 15,
      name: 'Microtel Inn & Suites By Wyndham Toluca',
      url: '/microtel/toluca-mexico/microtel-inn-and-suites-toluca/rooms-rates',
    }, 

    //Torreon

    {
      id: 146,
      cityId: 28,
      countryId: 15,
      name: 'Wyndham Torreon',
      url: '/wyndham/torreon-mexico/wyndham-torreon/rooms-rates',
    }, 

    //Veracruz

    {
      id: 147,
      cityId: 29,
      countryId: 15,
      name: 'Ramada Plaza By Wyndham Veracruz Boca Del Rio',
      url: '/ramada/boca-del-rio-mexico/ramada-plazapboca-del-rio/rooms-rates',
    }, 
    {
      id: 148,
      cityId: 29,
      countryId: 15,
      name: 'Howard Johnson By Wyndham Veracruz',
      url: '/hojo/veracruz-mexico/howard-johnson-hotel-veracruz/rooms-rates',
    }, 
    {
      id: 149,
      cityId: 29,
      countryId: 15,
      name: 'Wyndham Olimpia Royal Hotel',
      url: '/wyndham/olimpia-brazil/wyndham-olimpia-royal-hotel/rooms-rates',
    }, 

    //Yucatan

    {
      id: 150,
      cityId: 30,
      countryId: 15,
      name: 'Wyndham Merida',
      url: '/wyndham/merida-mexico/wyndham-merida/rooms-rates',
    }, 

    ///PAN

    //Panama City

    {
      id: 151,
      cityId: 1,
      countryId: 16,
      name: 'Bristol Panama, a Registry Collection hotel',
      url: '/registry-collection/panama-city-panama/bristol-panama-a-registry-collection-hotel/rooms-rates',
    },
    {
      id: 152,
      cityId: 1,
      countryId: 16,
      name: 'Tryp By Wyndham Panama Centro',
      url: '/tryp/panama-city-panama/tryp-panama-centro/rooms-rates',
    },
    {
      id: 153,
      cityId: 1,
      countryId: 16,
      name: 'Ramada Plaza By Wyndham Panama Punta Pacifica',
      url: '/ramada/panama-city-panama/ramada-plaza-panama-punta-pacifica/rooms-rates',
    },
    {
      id: 154,
      cityId: 1,
      countryId: 16,
      name: 'Ramada By Wyndham Panama Centro Via Argentina',
      url: '/ramada/panama-city-panama/ramada-panama-centro/rooms-rates',
    },

    ///PAR

    //Asuncion

    {
      id: 155,
      cityId: 1,
      countryId: 17,
      name: 'Dazzler By Wyndham Asuncion',
      url: '/dazzler/asuncion-paraguay/dazzler-hotel-asuncion/rooms-rates',
    },
    {
      id: 156,
      cityId: 1,
      countryId: 17,
      name: 'Esplendor By Wyndham Asuncion',
      url: '/esplendor/asuncion-paraguay/esplendor-by-wyndham-asuncion/rooms-rates',
    },

    //Ciudad del Este
    {
      id: 157,
      cityId: 2,
      countryId: 17,
      name: 'Dazzler by Wyndham Ciudad del Este',
      url: '/dazzler/ciudad-del-este-paraguay/dazzler-ciudad-del-este/rooms-rates',
    },
    {
      id: 158,
      cityId: 2,
      countryId: 17,
      name: 'Howard Johnson By Wyndham Ciudad Del Este',
      url: '/hojo/ciudad-del-este-paraguay/howard-johnson-ciudad-del-este/rooms-rates',
    },

    ///PERU

    //Arequipa

    {
      id: 159,
      cityId: 1,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Arequipa',
      url: '/wyndham/arequipa-peru/wyndham-arequipa/rooms-rates',
    },

    //Camarja

    {
      id: 160,
      cityId: 2,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Cajamarca',
      url: '/wyndham/cajamarca-peru/wyndham-costa-del-sol-cajamarca/rooms-rates',
    },

    //Chiclayo

    {
      id: 161,
      cityId: 3,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Chiclayo',
      url: '/wyndham/chiclayo-peru/wyndham-costa-del-sol-chiclayo/rooms-rates',
    },

    //Cusco

    {
      id: 162,
      cityId: 4,
      countryId: 18,
      name: 'Wyndham Costa del Sol Cusco',
      url: '/wyndham/cusco-peru/wyndham-costa-del-sol-cusco/rooms-rates',
    },

    //Lima

    {
      id: 163,
      cityId: 5,
      countryId: 18,
      name: 'Dazzler By Wyndham Lima Miraflores',
      url: '/dazzler/lima-peru/dazzler-hotel-lima-miraflores/rooms-rates',
    },
    {
      id: 164,
      cityId: 5,
      countryId: 18,
      name: 'Dazzler By Wyndham Lima San Isidro',
      url: '/dazzler/lima-peru/dazzler-lima-san-isidro/rooms-rates',
    },
    {
      id: 165,
      cityId: 5,
      countryId: 18,
      name: 'Howard Johnson By Wyndham Lima Miraflores Larco',
      url: '/hojo/lima-peru/howard-johnson-lima-miraflores/rooms-rates',
    },
    {
      id: 166,
      cityId: 5,
      countryId: 18,
      name: 'Ramada Encore By Wyndham Lima San Isidro',
      url: '/ramada/lima-peru/ramada-encore-lima-san-isidro/rooms-rates',
    },
    {
      id: 167,
      cityId: 5,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Lima Airport',
      url: '/wyndham/lima-peru/wyndham-costa-del-sol-lima-airport/rooms-rates',
    },
    {
      id: 168,
      cityId: 5,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Lima City',
      url: '/wyndham/lima-peru/wyndham-costa-del-sol-lima-salaverry/rooms-rates',
    },

    //Piura

    {
      id: 169,
      cityId: 6,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Piura',
      url: '/wyndham/piura-peru/wyndham-costa-del-sol-piura/rooms-rates',
    },

    //Pucallpa

    {
      id: 170,
      cityId: 7,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Pucallpa',
      url: '/wyndham/pucallpa-peru/wyndham-costa-del-sol-pucallpa/rooms-rates',
    },

    //Trujillo

    {
      id: 171,
      cityId: 8,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Trujillo',
      url: '/wyndham/trujillo-peru/wyndham-costa-del-sol-trujillo/rooms-rates',
    },

    //Tumbes

    {
      id: 172,
      cityId: 9,
      countryId: 18,
      name: 'Wyndham Costa Del Sol Tumbes',
      url: '/wyndham/tumbes-peru/wyndham-costa-del-sol-tumbes/rooms-rates',
    },

    ///P RICO

    //Isla Verde

    {
      id: 173,
      cityId: 1,
      countryId: 19,
      name: 'Tryp By Wyndham Isla Verde',
      url: '/tryp/carolina-puerto-rico/tryp-by-wyndham-isla-verde/rooms-rates',
    },

    //Mayaguez

    {
      id: 174,
      cityId: 2,
      countryId: 19,
      name: 'TRYP by Wyndham Mayaguez',
      url: '/tryp/mayaguez-puerto-rico/tryp-mayaguez/rooms-rates',
    },

    ///REP DOM

    //Bahia Conon

    {
      id: 175,
      cityId: 1,
      countryId: 20,
      name: 'Viva Wyndham V Samana - All-Inclusive Resort, Adults Only',
      url: '/wyndham/bahia-de-coson-dominican-republic/viva-wyndham-samana-all-inclusive-resort-adults-only/rooms-rates',
    },

    //Cabarete

    {
      id: 176,
      cityId: 2,
      countryId: 20,
      name: 'Viva Wyndham Tangerine - All-Inclusive Resort',
      url: '/wyndham/cabarete-dominican-republic/viva-wyndham-tangerine-all-inclusive-resort/rooms-rates',
    },

    //Cap Cana

    {
      id: 177,
      cityId: 3,
      countryId: 20,
      name: 'TRS Cap Cana Adults Only - All Inclusive Hotel',
      url: '/registry-collection/punta-cana-dominican-republic/trs-cap-cana-adults-only-all-inclusive-hotel/rooms-rates',
    },

    //La romana

    {
      id: 178,
      cityId: 4,
      countryId: 20,
      name: 'Viva Wyndham Dominicus Palace - All-Inclusive Resort',
      url: '/wyndham/la-romana-dominican-republic/viva-wyndham-dominicus-palace-all-inclusive-resort/rooms-rates',
    },
    {
      id: 179,
      cityId: 4,
      countryId: 20,
      name: 'Viva Wyndham Dominicus Beach - All-Inclusive Resort',
      url: '/wyndham/la-romana-dominican-republic/viva-wyndham-dominicus-beach-an-all-inclusive-resort/rooms-rates',
    },

    //Puerto Plata

    {
      id: 180,
      cityId: 5,
      countryId: 20,
      name: 'Viva Wyndham V Heavens - All-Inclusive Resort, Adults Only',
      url: '/wyndham/puerto-plata-dominican-republic/viva-wyndham-v-heavens-all-inclusive-resort-adults-only/rooms-rates',
    },

    //Punta Cana

    {
      id: 181,
      cityId: 6,
      countryId: 20,
      name: 'Grand Palladium Bavaro Suites, All Inclusive Resort & Spa',
      url: '/registry-collection/punta-cana-dominican-republic/grand-palladium-bavaro-suites-all-inclusive-resort-and-spa/rooms-rates',
    },

    //Santo Domingo

    {
      id: 182,
      cityId: 7,
      countryId: 20,
      name: 'Ramada By Wyndham Princess Santo Domingo',
      url: '/ramada/santo-domingo-dominican-republic/ramada-santo-domingo-princess/rooms-rates',
    },

    ///SUR

    //Paramaribo

    {
      id: 183,
      cityId: 1,
      countryId: 21,
      name: 'Ramada By Wyndham Princess Paramaribo',
      url: '/ramada/paramaribo-suriname/ramada-paramaribo-princess-hotel/rooms-rates',
    },

    ///URU

    //Colonia

    {
      id: 184,
      cityId: 1,
      countryId: 22,
      name: 'Dazzler By Wyndham Colonia',
      url: '/dazzler/colonia-del-sacramento-uruguay/dazzler-colonia/rooms-rates',
    },

    //Montevideo

    {
      id: 185,
      cityId: 2,
      countryId: 22,
      name: 'Dazzler By Wyndham Montevideo',
      url: '/dazzler/montevideo-uruguay/dazzler-hotel-montevideo/rooms-rates',
    },
    {
      id: 186,
      cityId: 2,
      countryId: 22,
      name: 'Esplendor By Wyndham Montevideo Cervantes',
      url: '/esplendor/montevideo-uruguay/esplendor-hotel-cervantes/rooms-rates',
    },

    ///VEN

    //Porlamar

    {
      id: 187,
      cityId: 1,
      countryId: 23,
      name: 'Wyndham Isla Margarita Concorde',
      url: '/wyndham/porlamar-venezuela/wyndham-isla-margarita-concorde/rooms-rates',
    },

    ///V. ISL

    //British Virgin island

    {
      id: 188,
      cityId: 1,
      countryId: 24,
      name: 'Wyndham Tortola Bvi Lambert Beach Resort',
      url: '/wyndham/east-end-british-virgin-island/wyndham-tortola-bvi-lambert-beach-resort/rooms-rates',
    },

  ],
}
